<template>

	<div class='tag-input'>
		<div class="invalid-feedback">{{error}}</div>

		<div class="form-list show">
			<div class="form-list-item">
				<div class="form-list-input">
					<input class="form-control"
						   id="blockDomains"
						   @keydown.enter='addTag'
						   @keydown.188='addTag'
						   v-model="value"
						   type="text"
						   @input="error=''"
						   :placeholder="placeholder">
					<button class="btn btn-primary" @click='addTag'>Add</button>
				</div>
			</div>
		</div>

		<div class="alert alert-primary show" role="alert" v-if="tags.length">
			<div class="domains-list">
				<div v-for='(tag, index) in tags' :key='tag'   class="domains-list__item">
					<div class="domains-list__txt">{{tag}}</div>
					<span class="domains-list__delete" @click='removeTag(index)' ><i class="icon-close"></i></span>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
    import { validate } from 'vee-validate';

    export default {
        name: "TagInput",
        props: {
            tags: {
                type: Array
            },
            placeholder: {
                type: String,
                required: false,
                default: 'Enter Tag'
            },
            validate: {
                type: String,
                required: false,
                default: 'required'
            },
            name: {
                type: String,
                required: false,
                default: 'Field'
            }
        },
        data() {
            return {
                value: '',
				error: '',
            }
        },
        methods: {
            addTag(event) {
                event.preventDefault();
                validate(this.value, this.validate).then(result => {
                    if (result.valid) {
                        if (this.tags && this.tags.includes(this.value)) {
                            this.error = this.name + ' already exists'
						} else {
                            this.$emit('addTag', this.value);
                            this.value = ''
						}

                    } else {
                        if(this.validate !== 'required')
                        this.error = this.name + ' is not valid'
					}
                });


            },
            removeTag(index) {
                this.$emit('removeTag', index)
            }
        }
    }
</script>
<style scoped>
	.invalid-feedback {
		display: block;
	}
</style>
