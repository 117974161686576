<template>
	<div>
		<PageHeader title="View My Leads"
					:show-settings-button="true"
					button-name="Leads Settings"
					:onSettingsClick="openSettings"/>


		<Modal modalId='leadSettings'
			   :handle-ok="saveSettings"
			   title="My Leads Settings"
			   :no-close-on-backdrop=true
			   submit-button-text="Save"
			   size="lg">
			<form>
				<RadioGroup
						id="validate_email"
						v-model="form.validate_email"
						:options="yesNoOptions"
						label="Validate the email id’s for its correctness real-time"
						:inline="true"
				/>

				<div class="form-group">
					<div v-if="form.validate_email" class="mt-3">
						<div class="alert alert-primary" role="alert">
							We verify 100 junk or spam emails for free. ({{never_bounce_limit}}/100 verification
							remaining)
<!--							Todo add checking for Upgrade plan -->
<!--							<p>-->
<!--								<router-link to="#"> Upgrade Plan</router-link>-->
<!--							</p>-->

						</div>
					</div>

				</div>

				<!--				Block URLs-->
				<RadioGroup
						id="block_urls"
						v-model="form.block_urls"
						:options="yesNoOptions"
						label="Blacklist URL(s)"
						:inline="true"
						description="User will not be able to do audit on this website"
				/>

				<div class="form-group">
					<div v-if="form.block_urls">
						<TagInput
								:tags="block_urls_list"
								placeholder="Enter URLs"
								@addTag="block_urls_list.push($event)"
								@removeTag="block_urls_list.splice($event, 1)">
						</TagInput>
					</div>
				</div>

				<!--				Block Emails-->
				<RadioGroup
						id="block_email_address"
						v-model="form.block_email_address"
						:options="yesNoOptions"
						label="Block specific email addresses"
						:inline="true"
						description="Multiple email addresses can be added"
				/>
				<div class="form-group">
					<div v-if="form.block_email_address">
						<TagInput
								:tags="block_emails_list"
								validate="email"
								name="Email address"
								placeholder="Enter email addresses"
								@addTag="block_emails_list.push($event)"
								@removeTag="block_emails_list.splice($event, 1)">
						</TagInput>
					</div>
				</div>

				<!--				Block Domains-->
				<div class="form-group">

					<RadioGroup
							id="block_email_domains"
							v-model="form.block_email_domains"
							:options="yesNoOptions"
							label="Block specific email domains"
							:inline="true"
							description="Multiple email domains can be added"
					/>

					<div v-if="form.block_email_domains">
						<TagInput
								:tags="block_domains_list"
								placeholder="Enter email domains"
								@addTag="block_domains_list.push($event)"
								@removeTag="block_domains_list.splice($event, 1)">
						</TagInput>
					</div>
				</div>

				<!--				Send lead notification-->


				<RadioGroup
						id="send_notifications"
						v-model="form.send_notifications"
						:options="yesNoOptions"
						label="Send lead notification to your team member(s)"
						:inline="true"
						description="Multiple email addresses can be added"
				/>
				<div class="form-group">
					<div v-if="form.send_notifications">
						<TagInput
								:tags="send_notifications_list"
								validate="email"
								name="Email address"
								placeholder="Enter team member(s) email addresses"
								@addTag="send_notifications_list.push($event)"
								@removeTag="send_notifications_list.splice($event, 1)">
						</TagInput>
					</div>
				</div>
			</form>
		</Modal>

	</div>
</template>

<script>
    import PageHeader from "../../../../../../shared/components/PageHeader";
    import Modal from "../../../../../../shared/components/Modal";
    import TagInput from "../../../../../../shared/components/TagInput";
    import RadioGroup from "../../../../../../shared/components/RadioGroup";

    export default {
        name: "lead-header",
        components: {TagInput, Modal, PageHeader, RadioGroup},
        data() {
            return {
                yesNoOptions: [
                    {
                        value: 0,
                        text: 'No',
                    },
                    {
                        value: 1,
                        text: 'Yes',
                    },
                ],

                never_bounce_limit: 0,

                block_urls_list: [],
                block_emails_list: [],
                block_domains_list: [],
                send_notifications_list: [],
                form: {
                    validate_email: 0,
                    block_email_address: 0,
                    block_email_domains: 0,
                    block_urls: 0,
                    send_notifications: 0,
                },

                oldData: {
                    validate_email: 0,
                    block_email_address: 0,
                    block_email_domains: 0,
                    block_urls: 0,
                    send_notifications: 0,
                },
                oldDataLists: {
                    block_urls_list: [],
                    block_emails_list: [],
                    block_domains_list: [],
                    send_notifications_list: [],
                },


            }
        },
        props: {},

        methods: {
            saveSettings(event) {


                event.preventDefault();
                let sendData = {
                    never_bounce: this.form.validate_email,
                    email_address: this.form.block_email_address,
                    email_domain: this.form.block_email_domains,
                    whitelist_radio: this.form.block_urls,
                    add_emailalert: this.form.send_notifications,

                    whitelist_urls: this.form.block_urls === this.oldData.block_urls ? this.oldDataLists.block_urls_list : this.block_urls_list,
                    block_emails: this.form.block_email_address === this.oldData.block_email_address ? this.oldDataLists.block_emails_list : this.block_emails_list,
                    block_domains: this.form.block_email_domains === this.oldData.block_email_domains ? this.oldDataLists.block_domains_list : this.block_domains_list,
                    alert_emails: this.form.send_notifications === this.oldData.send_notifications ? this.oldDataLists.send_notifications_list : this.send_notifications_list,

                };
                this.$store.dispatch("leads/saveSettings", sendData).then(() => {
                    this.showNotifications({message: 'Settings were successfully saved', type: 'success'});
                    this.$bvModal.hide('leadSettings');
                }).catch(error => {
                    this.showErrorNotifications(error)
                })


            },
            openSettings() {
                this.$store.dispatch("leads/getSettings").then((response) => {
                    this.never_bounce_limit = response.never_bounce_limit;

                    this.form.validate_email = this.oldData.validate_email = response.never_bounce | 0;
                    this.form.block_email_address = this.oldData.block_email_address = response.email_address | 0;
                    this.form.block_email_domains = this.oldData.block_email_domains = response.email_domain | 0;
                    this.form.block_urls = this.oldData.block_urls = response.whitelist_radio | 0;
                    this.form.send_notifications = this.oldData.send_notifications = response.add_emailalert | 0;

                    this.block_urls_list = this.oldDataLists.block_urls_list = response.whitelist_urls ?? [];
                    this.block_emails_list = this.oldDataLists.block_emails_list = response.block_emails ?? [];
                    this.block_domains_list = this.oldDataLists.block_domains_list = response.block_domains ?? [];
                    this.send_notifications_list = this.oldDataLists.send_notifications_list = response.alert_emails ?? [];

                    this.$bvModal.show('leadSettings');
                })

            }
        }

    }
</script>
