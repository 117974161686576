<template>
	<div>
		<LeadHeader></LeadHeader>
		<div class="leads">
			<div class="container">
				<Tab :items="tabItems"></Tab>
				<router-view></router-view>
			</div>
		</div>
	</div>

</template>

<script>
    import LeadHeader from "./components/LeadHeader";
    import Tab from "../../../../../shared/components/Tab"
    export default {
        name: "Leads",
        components: {LeadHeader, Tab},
        data() {
            return {
                tabItems: [
                    {
                        name: 'my-leads',
                        route: {name: 'dashboard.leads.my-leads'},
                        title: 'My Leads'
                    },
                    {
                        name: 'blocked-leads',
                        route: {name: 'dashboard.leads.blocked-leads'},
                        title: 'Blocked Leads'
                    },
                ]
            }
        }
    }
</script>

<style lang="scss">
	@import "../../../../../shared/assets/styles/blocks/leads";
</style>
